import styled from "styled-components";

export const CustomSegmented = styled.div`
  .ant-segmented {
    box-sizing: border-box;
    margin: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    display: inline-block;
    padding: 6px;
    color: #4a4a4a;
    background: linear-gradient(145deg, #e0eafc, #cfdef3);
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .ant-segmented:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .ant-segmented-item-selected {
    background: linear-gradient(145deg, #6dd5ed, #2193b0);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
  }

  .ant-segmented-item-selected:hover {
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .ant-segmented-item,
  .ant-segmented-item-label {
    padding: 1px 13px !important;
  }

  .ant-segmented-item:hover,
  .ant-segmented-item:focus {
    color: #1f78d1;
  }

  .ant-segmented-thumb {
    background: linear-gradient(145deg, #6dd5ed, #2193b0);
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
`;

export const SearchInputContainer = styled.div``;

export const KumitiScorePanelWrapper = styled.div`
  .ant-table-tbody > tr > td .ant-table-thead > tr > th {
    padding: 3px;
  }

  .score-sheet-heading {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
`;
export const CustomTable = styled.div`
  .ant-table {
    /* border: 1px solid red; */
    .ant-table-body {
      min-height: calc(
        -325px + 100vh
      ); // gives the minimum height to the table body
    }
  }
  // table header -titles
  .ant-table-thead > tr > th {
    color: #8c8c8c;
    font-weight: 700;
    background-color: transparent;
    font-size: 13px;
    padding: 16px 6px;
  }
  // table body
  .ant-table-tbody > tr > td {
    padding: 16px 6px;
    /* color: #000000; */
    font-size: 14px;
    /* font-weight: 700; */
  }
  // table page-size dropdown
  .ant-pagination-options {
    .ant-select {
      font-weight: 600;
      .ant-select-selector {
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 30px !important;
        }
        .ant-select-selection-item {
        }
      }
    }
  }
`;
