import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Segmented,
  Select,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation,Link } from "react-router-dom";
import event from "../../../../services/event";
import { capitalizeFirstLetter, notify } from "../../../../utils/helper";

import moment, { duration } from "moment";

import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import { CustomSegmented } from "../Styled";
import { DeleteOutlined } from "@ant-design/icons";
import auth from "../../../../services/auth";
import { filterOnlineJudge } from "../../../../utils/utils";

const EventTatamiesCategories = () => {
  const [loading, setLoading] = useState(false);
  const [tatamiDataCategory, setTatamiDataCategory] = useState([]);
  const [durationOfCategoryInTatami, setDurationOfCategoryInTatami] = useState("00:00:00");
  const [timers, setTimers] = useState({});
  const [tatamiDataCategoryUpdated, setTatamiDataCategoryUpdated] = useState([]);
  // const [selectedSection, setSelectedSection] = useState("Categories");
  const [selectedSection, setSelectedSection] = useState(() => { return localStorage.getItem("selectedSection2") || "Categories";});
  const history = useHistory();
  const query = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const tatamiName = location?.state?.tatamiName;
  const officials = location?.state?.officials;

  const [totalActiveOfficial, setTotalActiveOfficial] = useState([])

  const getTatamiById = async (ringId) => {
    try {
      const res = await event.getTatamiById(ringId);
      setTatamiDataCategory(res?.data);
      const onlineOffiical = []
      Object.keys(res?.data?.kata)?.forEach((item) => {
        if(res?.data?.kata[item]) {
          onlineOffiical?.push({judge : res?.data?.kata[item], number : item})
        }
      })
      setTotalActiveOfficial(onlineOffiical)
    } catch (error) {}
  };



  useEffect(() => {
    if (query?.ringId) {
      getTatamiById(query?.ringId);
    }
  }, [query?.ringId]);

  const addOrRemoveCategoryInTatami = async (categoryId, payload) => {
    try {
      setLoading(true);
      let res = await event.addOrRemoveCategoryInTatami(categoryId, payload);
      // return res?.data;
      setLoading(false);
      notify("success", "Tatami Status Changed");
      getTatamiById(query?.ringId);
    } catch (err) {
      notify("error", err?.data?.message);
      setLoading(false);
    }
  };

  const handleStart = async (id) => {
    try {
      const apiData = await event.addOrRemoveCategoryInTatami(id, {
        inPlay: true,
      });
      const startTime = apiData?.data?.start;
      const Catid = apiData?.data?.category?.category?._id;

      const updatedCategories = tatamiDataCategory?.category.map((category) => {
        if (category._id === Catid) {
          return {
            ...category,
            startTime: moment(startTime).format("DD MMM YYYY hh:mm:ss A"),
            inPlay: true,
            stopTime: "00:00:00",
            playTime: "00:00:00",
          };
        }
        return category;
      });

      // setTatamiDataCategory((prevState) => ({
      //   ...prevState,
      //   category: updatedCategories,
      // }));
    } catch (error) {}
  };

  const handleStop = async (id) => {
    try {
      const apiData = await event.addOrRemoveCategoryInTatami(id, {
        inPlay: false,
      });
      const stopTime = apiData?.data?.start;
      const Catid = apiData?.data?.category?.category?._id;
      const playTime = apiData?.data?.category?.totalPlayTime;

      const updatedCategories = tatamiDataCategory?.category.map((category) => {
        if (category._id === Catid) {
          return {
            ...category,
            stopTime: moment(stopTime).format("DD MMM YYYY hh:mm:ss A"),
            inPlay: false,
            playTime: playTime,
          };
        }
        return category;
      });
    } catch (error) {}
  };

  let data = [];

  for (let item of tatamiDataCategory?.category || []) {
    const timer = timers[item?._id] || {};
    data.push({
      key: item?._id,

      // name: item?.name || "-",
      name: item?.name ? (
        <Link
          to={{
            // pathname: `/pages/events/tatami/category/${item?._id}/match`,
            pathname: `/pages/events/all-events/category/${item?._id}/matches`,
            state: { name: item?.name, type: item?.type },
          }}
          style={{ cursor: "pointer" }}
          className="ellipsis"
        >
          <div
            className="ellipsis"
            style={{
              fontWeight: "600",
              fontSize: "13px",
              color: "var(--text-primary)",
              lineHeight: "15px",
              letterSpacing: "0.9px",
            }}
          >
            {item?.name}
          </div>
        </Link>
      ) : (
        "-"
      ),

      nameToSort: item?.name || "-",

      event: <div>{item?.event ? item?.event : "-"}</div>,
      type: item?.type ? item?.type : "-",

      available: item?.inPlay ? "No" : "Yes",
      startTime: item?.playTimestamp
        ? moment(item?.playTimestamp).format("DD MMM YYYY hh:mm:ss A")
        : "00:00:00",
      stopTime: item?.stopTimestamp
        ? moment(item?.stopTimestamp).format("DD MMM YYYY hh:mm:ss A")
        : "00:00:00",
      duration: item?.totalTimeInPlay
        ? moment.utc(item?.totalTimeInPlay).format("HH:mm:ss")
        : "00:00:00",
      action: (
        <div className="text-center">
          {!item?.inPlay ? (
            <Button
              type="button"
              size="small"
              onClick={() => {
                addOrRemoveCategoryInTatami(item?._id, { inPlay: true });
                // handleStart(item?._id);
              }}

              // }
            >
              Start
            </Button>
          ) : (
            <Button
              type="button"
              size="small"
              onClick={() => {
                addOrRemoveCategoryInTatami(item?._id, { inPlay: false });
                // handleStop(item?._id);
              }}
            >
              Stop
            </Button>
          )}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "Catagory Name",
      dataIndex: "name",
      key: "name",
      // sorter: (a, b) => a.score - b.score,
    },
    {
      title: "Event Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "Avaiable",
      dataIndex: "available",
      key: "available",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      align: "center",
    },
    {
      title: "Stop Time",
      dataIndex: "stopTime",
      key: "stopTime",
      align: "center",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
    },
  ];

  const eventDataOverviewdata = useSelector(
    (state) => state?.breadcrumb?.breadcrumbItems
  );

  useEffect(() => {
    if (!eventDataOverviewdata?.subtitle.includes(`${tatamiName}`)) {
      dispatch(
        setBreadcrumb({
          title: `${eventDataOverviewdata?.title}`,
          subtitle: `${eventDataOverviewdata?.subtitle} / ${tatamiName}`,
          url: "",
        })
      );
    }
  }, []);


  useEffect(() => {
    // Set the selected section when the component is mounted (if needed)
    const savedSection = localStorage.getItem("selectedSection2");
    if (savedSection) {
      setSelectedSection(savedSection);
    }
  }, []);

  const handleSegmentChange = (value) => {
    setSelectedSection(value);
    localStorage.setItem("selectedSection2", value); // Save selected section in local storage
  };

  return (
    <Row>
      <Col className="mb-2" span={24} style={{ textAlign: "start" }}>
        {/* <Button
          type="primary"
          className="font-bold cursor-pointer mx-4 p-0"
          onClick={() => history.goBack()}
        >
          Back
        </Button> */}
        <CustomSegmented>
          <Segmented
            options={["Categories", "Officials"]}
            // defaultValue={selectedSection}
            value={selectedSection}
            // onChange={(value) => {
            //   setSelectedSection(value);
            //   // history.push(`?section=${value}`);
            // }}
            onChange={handleSegmentChange}
          />
        </CustomSegmented>
      </Col>
      {selectedSection === "Categories" && (
        <Col span={24}>
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            tableLayout="auto"
            scroll={{ x: 500 }}
          />
        </Col>
      )}
      
      {selectedSection === "Officials" && (
        <Col span={24}>
          <OfficialsTable totalActiveOfficial = {totalActiveOfficial} officials={officials} ringId={query?.ringId} />
        </Col>
      )}
    </Row>
  );
};


export default EventTatamiesCategories;

const OfficialsTable = ({ officials, ringId , totalActiveOfficial}) => {
  const [modelOpen, setModelOpen] = useState(false);
  const [judgeId, setJudgeId] = useState(null);


  let data = [];
  for (let item of officials) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>
                {item?.firstName?.[0]?.toUpperCase() +
                  item?.lastName?.[0]?.toUpperCase() || "-"}
              </div>
            </Avatar>
          </div>

          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            {/* <Link
              // to={path().USER_DETAILS_STATE(item?._id)}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            > */}
            <div
              className="ellipsis"
              style={{
                fontWeight: "600",
                fontSize: "13px",
                color: "var(--text-primary)",
                lineHeight: "15px",
                letterSpacing: "0.9px",
              }}
            >
              {item?.firstName + " " + item?.lastName || "-"}
            </div>
            {/* </Link> */}
          </div>
        </div>
      ),
      nameToSort: item?.firstName
        ? item?.firstName + " " + item?.lastName
        : "-",
      verification: (
        <Tag
          style={{
            background: `${
              item?.isVerified ? "#006A4E" : "rgba(255, 191, 0, 0.1)"
            } `,
            color: `${item?.isVerified ? "#ffffff" : "#FFBF00"} `,
            border: "none",
            borderRadius: "6px",
            padding: "0 10px",
            fontWeight: `${item?.isVerified ? 600 : 300}`,
          }}
        >
          {item?.isVerified ? "Verified" : "Pending"}
        </Tag>
      ),
      email: item?.email ? item?.email : "-",

      role: item?.role ? capitalizeFirstLetter(item?.role) : "-",
      createdAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.createdAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      updatedAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.updatedAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),
      active: (
        // <div
        //   style={{
        //     color: `${item?.isActive? "green" : "red"}`,
        //   }}
        // >
        //   {item?.isActive? "Availabe" : "Offline"}
        // </div>
        <>
        <Tag
        style={{
          background: `${
            filterOnlineJudge(totalActiveOfficial, item?._id).active? "green" : "red"
          } `,
          color: `#fff`,
          border: "none",
          borderRadius: "6px",
          padding: "0 10px",
          fontWeight: 600,
        }}
      >
        {filterOnlineJudge(totalActiveOfficial, item?._id).active? "Online"  : "Offline"}
      </Tag> 
      <div className="mt-1">{filterOnlineJudge(totalActiveOfficial, item?._id).number}</div>
      </>
      ),

      action: (
        <div className="d-flex justify-content-center">
          {/* <DeleteOutlined
            onClick={() => {
              //   setDeleteShow(true);
              //   setSelectedUserId(item?._id);
            }}
            type="danger"
          /> */}

          {item?.role === "judge" ? (
            <Button
              onClick={() => {
                setModelOpen(true);
                setJudgeId(item?._id);
              }}
            >
              Generate QR
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        multiple: 3,
      },
    },
    {
      title: "ROLE",
      className: "ant-table-row-cell-break-word",
      dataIndex: "role",
      sorter: {
        compare: (a, b) => a.role.localeCompare(b.role),
        multiple: 2,
      },
    },
    {
      title: "EMAIL",
      className: "ant-table-row-cell-break-word",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 2,
      },
    },
    {
      title: "VERIFIED",
      dataIndex: "verification",
      className: "ant-table-row-cell-break-word",
    },
    // {
    //   title: "ROLE",
    //   dataIndex: "role",
    //   className: "ant-table-row-cell-break-word",
    // },

    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      //   align: "center",
      className: "ant-table-row-cell-break-word",
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      className: "ant-table-row-cell-break-word",
    },
    {
      title: "ACTIVE",
      dataIndex: "active",
      className: "ant-table-row-cell-break-word",
      width: 100,
      // align: "center",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      className: "ant-table-row-cell-break-word",
      width: 100,
      align: "center",
    },
  ];

  return (
    <>
      {modelOpen && (
        <GenerateQRModal
          modelOpen={modelOpen}
          setModelOpen={setModelOpen}
          tatamiId={ringId}
          judgeId={judgeId}
        />
      )}
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        tableLayout="auto"
        scroll={{ x: 500 }}
      />
    </>
  );
};

const GenerateQRModal = ({ modelOpen, setModelOpen, tatamiId, judgeId }) => {
  const [selectedJudge, setSelectedJudge] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  const allJudges = [
    { id: "1", name: "Judge 1", value: "judge1" },
    { id: "2", name: "Judge 2", value: "judge2" },
    { id: "3", name: "Judge 3", value: "judge3" },
    { id: "4", name: "Judge 4", value: "judge4" },
    { id: "5", name: "Judge 5", value: "judge5" },
  ];

  const generateJudgesQR = async (value) => {
    let paylaod = { tatamiId, judgeId, judgeNumber: value?.judgeNumber };
    try {
      const res = await auth.generateJudgeQRCode(paylaod);
      setQrCode(res?.data?.qrCode);
    } catch (error) {
    } finally {
    }
  };

  return (
    <Modal
      open={modelOpen}
      centered
      title=<h4>Generate QR</h4>
      onCancel={() => setModelOpen(false)}
      footer={false}
    >
      <Form layout="vertical" onFinish={generateJudgesQR}>
        <Col className="ml-10">
          <Row style={{ justifyContent: "center", gap: "20px" }}>
            <Col>
              <Form.Item
                name="judgeNumber"
                label="Select Judge"
                rules={[{ required: true, message: "Please select a judge" }]}
              >
                <Select
                  placeholder="Select a judge"
                  // value={id}
                  onChange={(e) => {
                    // setSelectedJudge(e);
                  }}
                  style={{ width: "250px" }}
                >
                  {allJudges.map((judge) => (
                    <Select.Option key={judge?.id} value={judge?.value}>
                      {judge?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{marginTop:'30px'}}>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                className="px-30 ml-10"
                // disabled={!selectedJudge}
              >
                Generate
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
      <Divider />
      <Row style={{ justifyContent: "center" }}>
        {qrCode && (
          <Col>
            <div>
              <img src={qrCode} alt="" />
            </div>
          </Col>
        )}
        {/* <Col className="text-center" span={12}>
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setModelOpen(false);
            }}
            className="px-30 ml-10"
          >
            Cancel
          </Button>
        </Col> */}
      </Row>
    </Modal>
  );
};
