import DataTable from "../component/applications/DataTable";
import FullCalender from "../component/applications/FullCalender";
import Kanban from "../component/applications/Kanban";
import Wizard from "../component/applications/Wizard";
import Invoice from "../component/pages/account/Invoice";
import Settings from "../component/pages/account/Settings";
import Alerts from "../component/pages/Alerts";
import Billing from "../component/pages/Billing";
import ChartLine from "../component/pages/ChartLine";
import CRM from "../component/pages/CRM";
import OrderDetails from "../component/pages/ecommerce/order/OrderDetails";
import OrderList from "../component/pages/ecommerce/order/OrderList";
import EditProduct from "../component/pages/ecommerce/Products/EditProduct";
import NewProduct from "../component/pages/ecommerce/Products/NewProduct";
import ProductPage from "../component/pages/ecommerce/Products/ProductPage";
import ActiveEvents from "../component/pages/event/ActiveEvents";
import AllEvents from "../component/pages/event/AllEvents";
import Categories from "../component/pages/event/categories";
import CategoryDetails from "../component/pages/event/categories/CategoryDetails";
import CreateCategory from "../component/pages/event/categories/CreateCategory";
import CreateEvent from "../component/pages/event/CreateEvent";
import EventDetails from "../component/pages/event/eventDetails/EventDetails";

import Tatami from "../component/pages/event/tatami";
import CreateTatami from "../component/pages/event/tatami/CreateTatami";
import TatamiDetails from "../component/pages/event/tatami/TatamiDetails";

import Expense from "../component/pages/event/expense/Expense";

import UpcomingEvents from "../component/pages/event/UpcomingEvents";
import UpdateEvent from "../component/pages/event/UpdateEvent";
import Home from "../component/pages/Home";
import Institute from "../component/pages/institutes";
import CreateInstitutes from "../component/pages/institutes/CreateInstitutes";
import InstituteDetails from "../component/pages/institutes/InstituteDetails";
import Notifications from "../component/pages/Notifications";
import Profile from "../component/pages/Profile";
import AllProject from "../component/pages/profile/AllProject";
import TimeLine from "../component/pages/projects/TimeLine";
import Rtl from "../component/pages/Rtl";
import UsersManagement from "../component/pages/userManagement";
import CoachOverview from "../component/pages/userManagement/coach";
import CreateUsers from "../component/pages/userManagement/CreateUsers";
import DistrictOverview from "../component/pages/userManagement/district";
import CreateUserAtDistrictLevel from "../component/pages/userManagement/district/CreateUserAtDistrictLevel";
import DistictAdmins from "../component/pages/userManagement/district/DistictAdmins";
import JudgeOverview from "../component/pages/userManagement/judge";
import CreateJudge from "../component/pages/userManagement/judge/CreateJudge";
import JudgeDetails from "../component/pages/userManagement/judge/JudgeDetails";
import ModeratorOverview from "../component/pages/userManagement/moderator";
import CreateJudgeAndReferee from "../component/pages/userManagement/moderator/CreateJudgeAndReferee";
import RefereeOverview from "../component/pages/userManagement/referee";
import CreateReferee from "../component/pages/userManagement/referee/CreateReferee";
import RefereeDetails from "../component/pages/userManagement/referee/RefereeDetails";
import StateOverview from "../component/pages/userManagement/state";
import CreateStateUsers from "../component/pages/userManagement/state/CreateStateUsers";
import StateAdmins from "../component/pages/userManagement/state/StateAdmins";
import StateUsersDetails from "../component/pages/userManagement/state/StateUsersDetails";
import StudentOverview from "../component/pages/userManagement/student";
import UserDetails from "../component/pages/userManagement/UserDetails";
import NewUsers from "../component/pages/users/NewUsers";
import AuthGuard from "./AuthGuard";
import { path } from "./path";
import ActiveEventDetails from "../component/pages/event/ActiveEventDetails";
import PasswordRecovery from "../component/pages/PasswordRecovery";
import CategoriesOfTatami from "../component/pages/event/tatami/CategoriesOfTatami";
import EventCategoryMatch from "../component/pages/event/eventDetails/EventCategoryMatch";
import KumiteScoreboard from "../component/pages/event/eventDetails/category/KumitiScoreBoard";
import EventTatamiesCategories from "../component/pages/event/eventDetails/EventTatamiesCategories";
import KataScoreDrawer from "../component/pages/event/eventDetails/category/KataScoreDrawer";
import KumiteScoreDrawer from "../component/pages/event/eventDetails/category/KumitiScoreDrawer";
import StartTimeDrawer from "../component/pages/event/eventDetails/category/KataSocreDisplay";
import KataFinalResultDrawer from "../component/pages/event/eventDetails/category/KataFinalResultDrawer";




const routes = [
  {
    path: "/pages/events/expense",
    exact: true,
    name: "expense",
    component: Expense,
  },
  {
    path: "/pages/events/update/:eventId",
    exact: true,
    name: "events",
    component: UpdateEvent,
  },
  {
    path: "/pages/create/events",
    exact: true,
    name: "events",
    component: CreateEvent,
  },
  {
    path: "/pages/events/all-events/:EventId",
    exact: true,
    name: "events",
    component: EventDetails,
  },
  {
    path: "/pages/events/all-events/:EventId/result-sheet/:catId",
    exact: true,
    name: "events",
    component: KataFinalResultDrawer,
  },
  {
    path: "/pages/events/all-events",
    exact: true,
    name: "events",
    component: AllEvents,
  },
  {
    path: "/pages/events/new-event",
    exact: true,
    name: "events",
    component: UpcomingEvents,
  },
  {
    path: "/pages/events/active-events",
    exact: true,
    name: "events",
    component: ActiveEvents,
  },
  {
    path: "/pages/events/active-events/:EventId/active-event-details",
    exact: true,
    name: "events",
    component: ActiveEventDetails,
  },
  {
    path: "/pages/events/all-events/:eventId/category/:categoryId/matches",
    exact: true,
    name: "events",
    component: EventCategoryMatch,
  },
  {
    path: path().KUMITI_SCORE_PANEL(":catid", ":id"),
    exact: true,
    name: "score panel",
    component: KumiteScoreboard,
  },
  {
    path: path().KUMITI_SCORE_PANEL_DRAWER(":catid"),
    exact: true,
    name: "score panel",
    component: KumiteScoreDrawer,
  },
  {
    path: "/pages/events/all-events/category/:catid/matches/kata-panel",
    exact: true,
    name: "kata score panel",
    component: KataScoreDrawer,
  },
  
  {
    path: "/pages/events/categories",
    exact: true,
    name: "events",
    component: Categories,
  },
  {
    path: "/pages/events/categories/create-category",
    exact: true,
    name: "events",
    component: CreateCategory,
  },
  {
    path: "/pages/events/categories/:categoryId/category-details",
    exact: true,
    name: "events",
    component: CategoryDetails,
  },
  {
    path: "/pages/events/tatami",
    exact: true,
    name: "events",
    component: Tatami,
  },
  {
    path: "/pages/events/create-tatami",
    exact: true,
    name: "events",
    component: CreateTatami,
  },
  {
    path: "/pages/events/tatami/:ringId/tatami-details",
    exact: true,
    name: "events",
    component: TatamiDetails,
  },
  {
    path: "/pages/events/tatami/:ringId/tatami-categories",
    exact: true,
    name: "events",
    // component: CategoriesOfTatami,
    component: EventTatamiesCategories,
  },
  {
    path: "/pages/events/tatami/category/:categoryId/match",
    exact: true,
    name: "events",
    // component: TatamiCategoryMatch,
    component: EventCategoryMatch,
  },

  {
    path: "/pages/events/all-events/tatami/:ringId/tatami-details",
    exact: true,
    name: "events",
    component: EventTatamiesCategories,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Default",
    component: Home,
    private: true,
    role: ["admin", "student"],
  },
  {
    path: "/dashboard/crm",
    exact: true,
    name: "CRM",
    component: CRM,
    // private: true,
  },
  {
    path: "/pages/profile/profile-overview",
    exact: true,
    name: "Profile Overview",
    component: Profile,
    private: true,
  },
  {
    path: "/pages/profile/all-projects",
    exact: true,
    name: "All Project",
    component: AllProject,
  },
  {
    path: "/pages/users/new-user",
    exact: true,
    name: "New User",
    component: NewUsers,
  },
  {
    path: "/pages/account/settings",
    exact: true,
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    exact: true,
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    exact: true,
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/projects/timeline",
    exact: true,
    name: "Timeline",
    component: StartTimeDrawer,
  },
  {
    path: "/pages/rtl",
    exact: true,
    name: "RTL",
    component: Rtl,
  },
  {
    path: "/pages/charts",
    exact: true,
    name: "Charts",
    component: ChartLine,
  },
  {
    path: "/pages/alerts",
    exact: true,
    name: "Alerts",
    component: Alerts,
  },
  {
    path: "/pages/notifications",
    exact: true,
    name: "Notification",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    exact: true,
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/applications/wizard",
    exact: true,
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-table",
    exact: true,
    name: "DataTable",
    component: DataTable,
  },
  {
    path: "/applications/calendar",
    exact: true,
    name: "Calender",
    component: FullCalender,
  },
  {
    path: "/ecommerce/products/new-product",
    exact: true,
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    exact: true,
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    exact: true,
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/orders/order-list",
    exact: true,
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/orders/order-details",
    exact: true,
    name: "Order List",
    component: OrderDetails,
  },
  // karate-routes
  {
    path: "/students/institute",
    exact: true,
    name: "Institute",
    // component: Institute,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <Institute {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["admin"],
  },
  {
    path: "/students/institute/create-institute",
    exact: true,
    name: "Create Institute",
    // component: CreateInstitutes,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <CreateInstitutes {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["admin"],
  },
  {
    path: path().STUDENTS_INSTITUTE_DETAILS(":id"),
    exact: true,
    name: "Institute Details",
    // component: InstituteDetails,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <InstituteDetails {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["admin"],
  },
  {
    path: "/users",
    exact: true,
    name: "Users",
    // component: UsersManagement,
    component: (props) => (
      <AuthGuard roles={["stateHead"]}>
        <UsersManagement {...props} />
      </AuthGuard>
    ),
    private: true,
  },
  // {
  //   path: path().USER_DETAILS_SUPERADMIN(':id'),
  //   exact: true,
  //   name: "User Details",
  //   component: UserDetails,

  // },
  // user-routes
  {
    path: "/users/state/state-head",
    exact: true,
    name: "State Head",
    component: (props) => (
      <AuthGuard roles={["stateHead"]}>
        <StateOverview {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["head"],
  },
  {
    path: "/users/state/create-state-head",
    exact: true,
    name: "Create State Heads",
    component: (props) => (
      <AuthGuard roles={["stateHead", "stateAdmin"]}>
        <CreateStateUsers {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["head"],
  },
  {
    path: "/users/state/state-admin",
    exact: true,
    name: "State Admin",
    // component: StateOverview,
    component: (props) => (
      <AuthGuard roles={["stateHead", "stateAdmin"]}>
        <StateAdmins {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["admin"],
  },
  {
    path: path().USER_DETAILS_STATE(":id"),
    exact: true,
    name: "State Users Details",
    // component: StateUsersDetails,
    component: (props) => (
      <AuthGuard roles={["stateHead", "stateAdmin", "districtHead", "coach"]}>
        <StateUsersDetails {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["admin"],
  },
  {
    path: "/users/district/district-head",
    exact: true,
    name: "District Head",
    // component: DistrictOverview,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <DistrictOverview {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["head"],
  },
  {
    path: "/users/district/create-users",
    exact: true,
    name: "District Create Users",
    // component: CreateUserAtDistrictLevel,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <CreateUserAtDistrictLevel {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["head"],
  },
  {
    path: "/users/district/district-admin",
    exact: true,
    name: "District Admin",
    // component: DistictAdmins,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <DistictAdmins {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["admin"],
  },
  {
    path: "/users/coach/coach-overview",
    exact: true,
    name: "Coach Overview",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <CoachOverview {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["coach"],
  },
  {
    path: "/users/moderator/moderator-overview",
    exact: true,
    name: "Moderator Overview",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <ModeratorOverview {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["coach"],
  },
  {
    path: "/users/moderator/moderator-overview/moderator-details/:id",
    exact: true,
    name: "Moderator-details",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <StateUsersDetails {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["coach"],
  },
  {
    path: "/users/moderator/moderator-overview/create-judge/referee",
    exact: true,
    name: "Create Moderator",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <CreateJudgeAndReferee {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["coach"],
  },
  {
    path: "/users/moderator/judge-overview",
    exact: true,
    name: "Judge Overview",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard roles={["moderator","stateHead","districtHead"]}>
        <JudgeOverview {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["moderator"],
  },
  {
    path: path().JUDGE_DETAILS(":id"),
    exact: true,
    name: "Judge Details",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard roles={["moderator","stateHead","districtHead"]}>
        <JudgeDetails {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["moderator"],
  },
  {
    path: "/users/moderator/judge-overview/create-judge",
    exact: true,
    name: "Create Judge",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard roles={["moderator","stateHead","districtHead"]}>
        <CreateJudge {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["coach"],
  },
  {
    path: "/users/moderator/referee-overview",
    exact: true,
    name: "Referee Overview",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard roles={["moderator","stateHead","districtHead"]}>
        <RefereeOverview {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["moderator"],
  },
  {
    path: path().REFEREE_DETAILS(":id"),
    exact: true,
    name: "Referee Detail",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard roles={["moderator","stateHead","districtHead"]}>
        <RefereeDetails {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["moderator"],
  },
  {
    path: "/users/moderator/referee-overview/create-referee",
    exact: true,
    name: "Create Referee",
    // component: CoachOverview,
    component: (props) => (
      <AuthGuard roles={["moderator","stateHead","districtHead"]}>
        <CreateReferee {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["coach"],
  },
  {
    path: "/users/coach/coach-overview/create-coach",
    exact: true,
    name: "Create Coach",
    // component: CreateUserAtDistrictLevel,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <CreateUserAtDistrictLevel {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["head"],
  },
  {
    path: "/users/coach/coach-overview/coach-details/:id",
    exact: true,
    name: "Coach Details",
    // component: CreateUserAtDistrictLevel,
    component: (props) => (
      <AuthGuard
        roles={["stateHead", "stateAdmin", "districtHead", "districtAdmin"]}
      >
        <StateUsersDetails {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["head"],
  },
  {
    path: "/users/students/students-overview",
    exact: true,
    name: "Student Overview",
    // component: StudentOverview,
    component: (props) => (
      <AuthGuard
        roles={[
          "stateHead",
          "stateAdmin",
          "districtHead",
          "districtAdmin",
          "coach",
        ]}
      >
        <StudentOverview {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["student"],
  },
  {
    path: "/users/students/students-overview/student-details/:id",
    exact: true,
    name: "Student Details",
    // component: StudentOverview,
    component: (props) => (
      <AuthGuard
        roles={[
          "stateHead",
          "stateAdmin",
          "districtHead",
          "districtAdmin",
          "coach",
        ]}
      >
        <StateUsersDetails {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["student"],
  },
  {
    path: "/users/students/students-overview/create-student",
    exact: true,
    name: "Create Student",
    // component: CreateUsers,
    component: (props) => (
      <AuthGuard
        roles={[
          "stateHead",
          "stateAdmin",
          "districtHead",
          "districtAdmin",
          "coach",
        ]}
      >
        <CreateUsers {...props} />
      </AuthGuard>
    ),
    private: true,
    role: ["head"],
  },
];

export default routes;
