import React, { Component, useEffect, useState } from "react";
import { Row, Col, Segmented } from "antd";
import EventOverview from "./EventOverview";
import { CustomSegmented } from "../Styled";
import { useParams, useHistory, useLocation } from "react-router";

import event from "../../../../services/event";
import EventCatagories from "./EventCatagories";
import EventParticipantsList from "./EventParticipantsList";
import EventExpenses from "./EventExpenses";
import EventTatami from "./EventTatami";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import EventResult from "./EventResult";
import expense from "../../../../services/expense";
import { formatFee } from "../../../../utils/helper";

const EventDetails = () => {
  // const [selectedSection, setSelectedSection] = useState("Overview");
  const [selectedSection, setSelectedSection] = useState(() => {
    return localStorage.getItem("selectedSection") || "Overview";
  });
  const [eventData, setEventData] = useState([]);
  const [eventDataOverview, setEventDataOverview] = useState("");
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterCoachName, setFilterCoachId] = useState([]);
  const [filterInstituteName, setFilterInstituteId] = useState([]);
  const [totalEventExpense, setTotalEventExpense] = useState(0);
  const [eventTeamParticipants, setEventTeamParticipants] = useState([]);
  const [type, setType] = useState("");
  const query = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   history.push(`?section=${"Overview"}`);
  // }, []);

  // const breadcrumbItems = useSelector((state) => state.breadcrumb.breadcrumbItems);

  const getEventById = async (searchText = "") => {
    try {
      const res = await event.getParticipantByEventId(
        query?.EventId,
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText,
        // JSON.stringify(filterStatus)
        filterCoachName.length > 0 ? filterCoachName[0] : "",
        filterInstituteName.length > 0 ? filterInstituteName[0] : "",
        type
      );
      // console.log('res?.data',res?.data)
      setEventData(res?.data?.response?.records);
      setCount(res?.data?.response?.totalItems);
    } catch (error) {}
  };

  const getEventTeamParticipants = async (searchText = "") => {
    try {
      const res = await event.getEventTeamParticipants(
        query?.EventId,
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setEventTeamParticipants(res?.data?.response?.records);
    } catch (error) {}
  };

  const getEventByIdd = async (searchText = "") => {
    try {
      const res = await event.getEventById(
        query?.EventId,
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText,
        // JSON.stringify(filterStatus)
        filterCoachName.length > 0 ? filterCoachName[0] : "",
        filterInstituteName.length > 0 ? filterInstituteName[0] : ""
      );
      setEventDataOverview(res?.data);
    } catch (error) {}
  };
  const getTotalEventExpenses = async (EventId) => {
    try {
      const res = await expense.getTotalEventExpenses(EventId);
      setTotalEventExpense(res?.data?.totalExpense);
    } catch (error) {}
  };

  useEffect(() => {
    if (query?.EventId) {
      getTotalEventExpenses(query?.EventId);
      getEventTeamParticipants();
    }
  }, [
    query?.EventId,
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
  ]);

  useEffect(() => {
    getEventByIdd();
    getEventById();
  }, [
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
    filterStatus,
    filterCoachName,
    filterInstituteName,
    type,
  ]);

  const handleSearch = (value) => {
    getEventById(value);
  };
  const handleSearchTeamByName = (value) => {
    getEventTeamParticipants(value);
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get("section");

    if (section) {
      // Update the selected section from the URL on page load
      setSelectedSection(section);
    }

    if (eventDataOverview?.name) {
      dispatch(
        setBreadcrumb({
          title: "Events",
          subtitle:
            eventDataOverview?.name + ` / ${section || selectedSection}`,
          url: "",
        })
      );
    }
  }, [eventDataOverview, location.search]);

  const handleSegmentChange = (value) => {
    setSelectedSection(value);
    localStorage.setItem("selectedSection", value);
    history.push(`?section=${value}`);
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col
          md={selectedSection === "Expenses" ? 24 : 24}
          lg={selectedSection === "Expenses" ? 16 : 24}
        >
          <CustomSegmented>
            <Segmented
              options={[
                "Overview",
                "Participants",
                "Categories",
                "Tatami",
                "Result",
                "Expenses",
              ]}
              // defaultValue={selectedSection}
              value={selectedSection}
              onChange={handleSegmentChange}
              // onChange={(value) => {
              //   setSelectedSection(value);
              //   history.push(`?section=${value}`);
              // }}
            />
          </CustomSegmented>
        </Col>
        {selectedSection === "Expenses" && (
          <Col span={8} sm={12} md={12} lg={8} style={{}}>
            <Row gutter={[12, 24]}>
              <Col span={12}>
                <div
                  style={{
                    border: "1px solid #31a1bd",
                    backgroundColor: "#d1def3",
                    padding: "8px",
                    borderRadius: "8px",
                    boxShadow: " 0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>Total Expenses (Rs)</h3>
                  <h2 style={{ textAlign: "center" }}>
                    {totalEventExpense ? formatFee(totalEventExpense) : "0"} /-
                  </h2>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    border: "1px solid #31a1bd",
                    backgroundColor: "#d1def3",
                    padding: "8px",
                    borderRadius: "8px",
                    boxShadow: " 0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    Total Fee Received (Rs)
                  </h3>
                  <h2 style={{ textAlign: "center" }}>1,22,000 /-</h2>
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {selectedSection === "Overview" && (
          <Col span={24}>
            <EventOverview eventData={eventDataOverview} />
          </Col>
        )}
        {selectedSection === "Participants" && (
          <Col span={24}>
            <EventParticipantsList
              eventData={eventData}
              eventTeamParticipants={eventTeamParticipants}
              count={count}
              setPagination={setPagination}
              handleChangeTable={handleChangeTable}
              handleSearch={handleSearch}
              handleSearchTeamByName={handleSearchTeamByName}
              setFilterStatus={setFilterStatus}
              setFilterCoachId={setFilterCoachId}
              setFilterInstituteId={setFilterInstituteId}
              type={type}
              setType={setType}
              getEventById={getEventById}
            />
          </Col>
        )}
        {selectedSection === "Categories" && (
          <Col span={24}>
            <EventCatagories eventId={query?.EventId} />
          </Col>
        )}
        {selectedSection === "Result" && (
          <Col span={24}>
            <EventResult eventData={eventDataOverview} />
          </Col>
        )}
        {selectedSection === "Expenses" && (
          <Col span={24}>
            <EventExpenses />
          </Col>
        )}
        {selectedSection === "Tatami" && (
          <Col span={24}>
            <EventTatami />
          </Col>
        )}
      </Row>
    </>
  );
};

export default EventDetails;
